<template>
  <div class="col-2 col-md-2" v-if="show">
    <h3 class="row_title">{{caption}}</h3>
    <div class="row">
      <div class="col-6 col-sm-12" v-for="(item, inx) in getItemListWithLimit" :key="inx">
        <div class="footer">
          <ul class="footer_list" >
            <li>
              <a class="footer-item" :href="`${originPath}/${item.url}`"><p class="ellipsis">{{ item.text }}</p></a>
            </li>
          </ul>
        </div>
      </div>
      <ul class="showmore-li"><li v-if="isShowMore"><a href="#" @click="showMore">{{$t("footer.read-more")}}</a></li></ul>
    </div>
  </div>
</template>

<script>
import TemplateCustomFooterColumn from './TemplateCustomFooterColumn';

export default {
  name: 'custom-footer-column',
  mixins: [TemplateCustomFooterColumn],
};
</script>

<style scoped>
  ul.showmore-li {
    list-style-type: none;
    margin-right: -16px;
  }
  ul.showmore-li li a {
    color: #e9e9e95d;
    text-decoration: none;
    font-weight: 300;
    transition: 0.5s;
  }

  a.footer-item{
    color: #e9e9e9 !important;
    text-decoration: none !important;
    font-weight: 300;
    transition: 0.5s;
  }
  a {
    color: #007bff;
    text-decoration: none !important;
    background-color: transparent;
  }
  .show-more-text {
    opacity: 0.5;
  }
  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 180px;
    white-space: nowrap;
  }
</style>
